import { Router } from 'i18n';
import { useEffect, useState, createContext } from 'react';

export interface AuthContextType {
  users?: {
    address?: String;
    email?: String;
    id?: String;
    image?: String;
    name?: String;
    permissions?: String[];
    phoneNumber?: String;
  };
  setUsers?: any;
  setIsLoginOpen?: any;
  isLoginOpen?: boolean;
  setPrevUrl?: any;
  prevUrl?: String;
  LogOut?: any;
}
export const AuthContext = createContext<AuthContextType>({} as never);

const AuthContextProvider = ({ children }) => {
  const [users, setUsers] = useState({});
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [prevUrl, setPrevUrl] = useState('');
  useEffect(() => {
    const usered = JSON.parse(window.localStorage.getItem('users'));
    if (usered) {
      setUsers(usered);
    } else setUsers(false);
  }, []);
  const LogOut: any = () => {
    setUsers(null);
    localStorage.removeItem('users');
    localStorage.removeItem('token');
    Router.push('/');
  };
  return (
    <AuthContext.Provider value={{ users, setUsers, setIsLoginOpen, isLoginOpen, prevUrl, setPrevUrl, LogOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
