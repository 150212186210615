import { createContext, useState, useEffect } from 'react';

export interface RoomCart {
  images: string[];
  maxGuest: number;
  night: number;
  order: number;
  price: number;
  qty: number;
  rating: number;
  roomDescription: string;
  roomFacilities: string[];
  roomId: string;
  roomName: string;
  quantity: Number;
}

export interface CartRoomContextType {
  cart: RoomCart[];
  cartOrderRoom: {
    totalRoom: number;
    totalPrice: number;
    hotelName: string;
    checkIn: any;
    checkOut: any;
    hotelImg: string;
    hotelId: string;
  };
}

export const CartRoomContext = createContext<CartRoomContextType>({} as never);

const CartRoomContextProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [cartAll, setCartAll] = useState({});

  const [cartOrderRoom, setCartOrderRoom] = useState({
    totalRoom: 0,
    totalPrice: 0,
    hotelName: '',
    checkIn: null,
    checkOut: null,
    hotelImg: '',
    hotelId: '',
  });
  useEffect(() => {
    const CartOrder = JSON.parse(localStorage.getItem('cart_order_room'));
    if (CartOrder) {
      setCart(CartOrder.cart);
      setCartOrderRoom(CartOrder.cartOrderRoom);
      setCartAll(CartOrder);
    }
  }, []);
  const addCart = (item: any, hotelName, checkIn, checkOut, hotelImg, hotelId) => {
    const cartOrdersRoom = {
      totalRoom: cartOrderRoom.totalRoom + 1,
      totalPrice: cartOrderRoom.totalPrice + item.price,
      hotelName: hotelName,
      checkIn: checkIn,
      checkOut: checkOut,
      hotelImg: hotelImg,
      hotelId: hotelId,
    };
    const carted = [...cart, { ...item, quantity: 1, prices: item.price }];
    const cartData = { cart: carted, cartOrderRoom: cartOrdersRoom };
    setCartOrderRoom(cartOrdersRoom);
    setCart(carted);
    setCartAll(cartData);
    localStorage.setItem('cart_order_room', JSON.stringify(cartData));
  };

  const restoreDefault = () => {
    setCart([]);
    setCartAll({});
    setCartOrderRoom({
      totalRoom: 0,
      totalPrice: 0,
      hotelName: '',
      checkIn: null,
      checkOut: null,
      hotelImg: '',
      hotelId: '',
    });
    localStorage.removeItem('cart_order_room');
  };

  const addQty = (item: any) => {
    setCartOrderRoom({
      ...cartOrderRoom,
      totalRoom: cartOrderRoom?.totalRoom + 1,
      totalPrice: cartOrderRoom.totalPrice + item.price,
    });
    const objIndex = cart.findIndex((select) => {
      return select.roomId === item.roomId;
    });
    let carted = cart;
    carted[objIndex].quantity += 1;
    carted[objIndex].prices = carted[objIndex].quantity * carted[objIndex].price;
    setCart(carted);
    const cartData = {
      cart: carted,
      cartOrderRoom: {
        ...cartOrderRoom,
        totalRoom: cartOrderRoom?.totalRoom + 1,
        totalPrice: cartOrderRoom.totalPrice + item.price,
      },
    };
    setCartAll(cartData);
    localStorage.setItem('cart_order_room', JSON.stringify(cartData));
  };

  const minusQty = (item: any) => {
    const objIndex = cart.findIndex((select) => {
      return select.roomId === item.roomId;
    });
    let carted = cart;
    if (carted[objIndex].quantity > 1) {
      carted[objIndex].quantity -= 1;
      carted[objIndex].prices = carted[objIndex].quantity * carted[objIndex].price;
      setCart(carted);
      const cartData = {
        cart: carted,
        cartOrderRoom: {
          ...cartOrderRoom,
          totalRoom: cartOrderRoom?.totalRoom - 1,
          totalPrice: cartOrderRoom.totalPrice - item.price,
        },
      };
      setCartAll(cartData);
      setCartOrderRoom({
        ...cartOrderRoom,
        totalRoom: cartOrderRoom?.totalRoom - 1,
        totalPrice: cartOrderRoom.totalPrice - item.price,
      });
      localStorage.setItem('cart_order_room', JSON.stringify(cartData));
    } else {
      const newCart = cart.filter((obj) => {
        return obj.roomId !== item.roomId;
      });

      setCart(newCart);
      setCartOrderRoom({
        ...cartOrderRoom,
        totalPrice: cartOrderRoom.totalPrice - item.price,
        totalRoom: cartOrderRoom.totalRoom - 1,
      });
      const cartData = {
        cart: newCart,
        cartOrderRoom: {
          ...cartOrderRoom,
          totalPrice: cartOrderRoom.totalPrice - item.price,
          totalRoom: cartOrderRoom.totalRoom - 1,
        },
      };
      setCartAll(cartData);
      localStorage.setItem('cart_order_room', JSON.stringify(cartData));
    }
  };

  const value = {
    cart,
    addCart,
    setCart,
    cartAll,
    cartOrderRoom,
    addQty,
    minusQty,
    restoreDefault,
  };

  return <CartRoomContext.Provider value={value}>{children}</CartRoomContext.Provider>;
};

export default CartRoomContextProvider;
