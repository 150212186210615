import React from 'react';

function FooterMobile() {
  return (
    <footer className="w-full footer px-0 pfooter items-center">
      <div className="flex flex-col items-center pt-10 ">
        <img src={process.env.LOGO_WHITE || '/img/logo-primary.svg'} height="30" width="100" alt="" />
        <p className="flex text-sm pt-8">Get Our Mobile Apps On</p>
        <a target="blank" href="https://play.google.com/store/apps/details?id=id.great.app">
          <img src="/img/googleplay-badge.png" className="cursor-pointer items-center h-20 pt-4" alt="" />
        </a>
      </div>

      <div className="flex flex-col items-center p-12">
        <p className="font-medium pb-6">ARTICLES</p>
        <p className="font-medium pb-6">CAREERS</p>
        <p className="font-medium pb-6">MICE</p>
        <p className="font-medium pb-6">FAQ</p>
        <p className="font-medium pb-6 whitespace-nowrap">CUSTOMER SERVICE</p>
        <p className="font-medium whitespace-nowrap">PRIVACY POLICY</p>
      </div>

      <div className="pb-6 flex flex-col items-center">
        <p className="font-medium pb-6">Social Media</p>
        <div className="flex items-center">
          <a target="blank" href="http://instagram.com/dafam_hotels/">
            <img className="pr-2" src="/img/instagram-icon.svg" alt="" />
          </a>
          <a target="blank" href="https://www.facebook.com/DafamHotellndonesia">
            <img className="pl-2" src="/img/facebook-icon.svg" alt="" />
          </a>
        </div>
      </div>

      <div className="flex items-center justify-center pb-6">
        <p className="text-xs font-light whitespace-nowrap">
          Copyright © 2023 Dafam Hotel Management. All rights reserved.
        </p>
      </div>
      <div className="max-full flex flex-col items-center">
        <img className="cursor-pointer max-w-200" src="/img/ag-logo.svg" />
        <p className="text-xl my-8 text-center">A Universe of Wondrous Hospitality Experience Like No Other</p>
        <div className="grid grid-cols-4 m:grid-cols-2 items-center justify-center  gap-5 px-5">
          <a target="blank" href="https://artotelgroup.com/stay">
            <img className="cursor-pointer" src="/img/artotel-logo.svg" />
          </a>
          <a target="blank" href="https://www.dafamhotels.com/">
            <img className="cursor-pointer" src="/img/dafam-logo.svg" />
          </a>
          <a target="blank" href="https://maxonehotels.com/">
            <img className="cursor-pointer" src="/img/maxone-logo.svg" />
          </a>
          <a target="blank" href="https://www.kyriad.com/id/hotel-kami/indonesia/">
            <img className="cursor-pointer" src="/img/kyriad-logo.svg" />
          </a>
        </div>
      </div>
    </footer>
  );
}

function FooterDesktop() {
  return (
    <footer className="w-full footer px-16 py-6 pfooter">
      <div className="flex items-center justify-between">
        <img src={process.env.LOGO_WHITE || '/img/logo-primary.svg'} height="30" width="100" alt="logo-white" />
        <div className="flex items-center mr-1">
          <p className="flex text-sm">Get Our Mobile Apps On</p>
          <a target="blank" href="https://play.google.com/store/apps/details?id=id.great.app">
            <img src="/img/googleplay-badge.png" className="flex items-center h-12 cursor-pointer" alt="" />
          </a>
        </div>
      </div>

      <div className="flex py-10 text-sm justify-between">
        <div className="flex flex-col">
          <p className="font-medium pb-4">ARTICLES</p>
          <p className="font-medium pb-4">CAREERS</p>
          <p className="font-medium pb-4">MICE</p>
          <p className="font-medium pb-4">FAQ</p>
          <p className="font-medium pb-4 whitespace-no-wrap">CUSTOMER SERVICE</p>
          <p className="font-medium pb-4">PRIVACY POLICY</p>
        </div>

        <div className="flex flex-col w-1/3">
          <div className="pb-6">
            <p className="font-medium pb-2">Head Office</p>
            <p className="p2footer text-xs font-light">Jalan Raung No. 15</p>
            <p className="p2footer text-xs font-light">Semarang 50232 | Central Java - Indonesia</p>
            <p className="p2footer text-xs font-light">Tel. +62 24 355 9111</p>
            <p className="p2footer text-xs font-light">Email: info@dafamhotels.com</p>
          </div>
          <div className="pb-6">
            <p className="font-medium pb-2">Jakarta Sales Office</p>
            <p className="p2footer text-xs font-light">Menara Ravindo, 15th Floor</p>
            <p className="p2footer text-xs font-light">Jalan Kebon Sirih Kav. 75</p>
            <p className="p2footer text-xs font-light">Jakarta Pusat 10340 | DKI Jakarta - Indonesia</p>
          </div>
        </div>

        <div className="flex flex-col">
          <div className="pb-6">
            <p className="font-medium">Subscribe To Our Newsletter</p>
            <div className="w-80 newsteteller-nav mt-6 rounded-lg flex justify-between">
              <img className="pl-4" src="/img/mail-icon.svg" />
              <input
                placeholder="Enter your email"
                type="text"
                className="bg-transparent ml-2 focus:outline-none justify-center bg-none"
              />
              <button className=" z-50 submit-but p-2 w-24 ml-2 rounded-lg justify-center focus:outline-none font-semibold">
                SUBMIT
              </button>
            </div>
          </div>

          <div className="w-2/3 pb-6">
            <p className="font-medium pb-2">Social Media</p>
            <div className="flex pl-3">
              <a target="blank" href="http://instagram.com/dafam_hotels/">
                <img className="pr-2 cursor-pointer" src="/img/instagram-icon.svg" />
              </a>
              <a target="blank" href="https://www.facebook.com/DafamHotellndonesia">
                <img className="pl-2 cursor-pointer" src="/img/facebook-icon.svg" />
              </a>
            </div>
          </div>

          <div className="flex items-center cursor-pointer">
            <p className="text-xs font-light">Copyright © 2020 Dafam Hotels. All rights reserved.</p>
          </div>
        </div>
      </div>
      <div className="max-full flex flex-col items-center">
        <img className="cursor-pointer" src="/img/ag-logo.svg" />
        <p className="text-3xl my-10 text-center">A Universe of Wondrous Hospitality Experience Like No Other</p>
        <div className="grid grid-cols-4  items-center justify-center gap-16">
          <a target="blank" href="https://artotelgroup.com/stay">
            <img className="cursor-pointer" src="/img/artotel-logo.svg" />
          </a>
          <a target="blank" href="https://www.dafamhotels.com/">
            <img className="cursor-pointer" src="/img/dafam-logo.svg" />
          </a>
          <a target="blank" href="https://maxonehotels.com/">
            <img className="cursor-pointer" src="/img/maxone-logo.svg" />
          </a>
          <a target="blank" href="https://www.kyriad.com/id/hotel-kami/indonesia/">
            <img className="cursor-pointer" src="/img/kyriad-logo.svg" />
          </a>
        </div>
      </div>
    </footer>
  );
}

export { FooterDesktop, FooterMobile };
